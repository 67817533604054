import React, {useState} from "react";


import App from "../../routes/index";


import {Link, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Badge, Button, Dropdown, Layout, Menu, Modal, Space} from "antd";
import {footerText} from "../../util/config";


import {
    BellFilled,
    BellOutlined,
    CaretDownFilled,
    DownOutlined,
    HomeFilled, LogoutOutlined,
    SearchOutlined,
    SettingFilled, SettingOutlined, UserOutlined
} from "@ant-design/icons";
import CompanyDrawer from "../../components/CompanyDrawer";
import {openCompanyDrawer} from "../../appRedux/actions";
import {DOCUMENT_BASE_URL, MAIN_MENU} from "../../constants/ServerUrl";
import {NoPageAccess} from "../../components/NoPageAccess";


const {Content, Header, Footer} = Layout;
const MainApp = () => {
    let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const {authUser} = useSelector(({auth}) => auth);
    let {pathname} = useSelector(({common}) => common);
    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    const userMenu = (
        <Menu>

            <Menu.Item key="1">
               <Link to="/settings"> <Button  type="link" icon={<SettingOutlined />} size="small">
                    Settings
                </Button></Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Badge dot>
                    <Button type="link" icon={<BellOutlined />} size="small">
                        Notifications
                    </Button>
                </Badge>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                <Button type="link" icon={<LogoutOutlined />} size="small" >
                    Logout
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout className="">

                <Header className="bg-white p-0 h-16 flex justify-between">



                    <div className="flex">


                    <div className="m-3 ml-10"
                        onClick={() => {
                            window.location.href = MAIN_MENU;
                        }}>

                        <img width="140" alt="Phillips Outsourcing Logo" src="/images/logo.png"/>
                    </div>
                    <Menu
                        className="text-base font-medium text-gray-500"
                        mode="horizontal"
                        defaultOpenKeys={[defaultOpenKeys]}
                        selectedKeys={[selectedKeys]}

                    >

                        <Menu.Item key="logo" className="logo">

                        </Menu.Item>
                        <Menu.Item key="home" className="text-black">
                            <Link to="/home" >
                                <span className=" text-black" >Home</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="clients" className="text-black">
                            <Link to="/clients" >
                                <span className=" text-black" >Clients</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="contacts" className="text-black">
                            <Link to="/contacts" >
                                <span className=" text-black" >Contacts</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="leads" className="text-black">
                            <Link to="/leads" >
                                <span className=" text-black" >Leads</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="campaigns" className="text-black">
                            <Link to="/campaigns" >
                                <span className=" text-black" >Campaigns</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="deals" className="text-black">
                            <Link to="/deals" >
                                <span className=" text-black" >Deals</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="tasks" className="text-black">
                            <Link to="/tasks" >
                                <span className=" text-black" >Tasks</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="meetings" className="text-black">
                            <Link to="/meetings" >
                                <span className=" text-black" >Meetings</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="bids" className="text-black">
                            <Link to="/bids" >
                                <span className=" text-black" >Bids</span>
                            </Link>
                        </Menu.Item>


                    </Menu>
                    </div>

                    {authUser && (
                        <div className="flex  justify-end space-x-3">
                            <div>
                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>

                            {authUser.profile_photo_path && (

                                <Dropdown overlay={userMenu} trigger={["hover"]} placement="bottomRight" className="mt-3">
                                    <Button type="link" icon={<DownOutlined />} className="user-dropdown">
                                        <Avatar src={<img width="40" className="rounded-full" alt={authUser.first_name}
                                                          src={`${DOCUMENT_BASE_URL}/${authUser.profile_photo_path}`}/>} />


                                        <span className="pl-1 text-black">{authUser.first_name}</span>
                                    </Button>
                                </Dropdown>
                            )}

                            {!authUser.profile_photo_path && (
                                <Dropdown overlay={userMenu} trigger={["hover"]} placement="bottomRight" className="mt-3">
                                    <Button type="link" icon={<DownOutlined />} className="user-dropdown">
                                        <Avatar src={<img width="40" className="rounded-full" alt="Phillips Outsourcing"
                                                          src="/images/app-icons/placeholder.jpg"/>} />


                                        <span className="pl-1 text-black">{authUser.first_name}</span>
                                    </Button>
                                </Dropdown>

                            )}



                        </div>)}

                    {!authUser && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>

                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>
                            <Dropdown overlay={userMenu} trigger={["hover"]} placement="bottomRight" className="mt-3">
                                <Button type="link" icon={<DownOutlined />} className="user-dropdown">
                                    <Avatar src={<img width="40" className="rounded-full" alt="Phillips Outsourcing"
                                                      src="/images/app-icons/placeholder.jpg"/>} />


                                    <span className="pl-1 text-black">Welcome</span>
                                </Button>
                            </Dropdown>
                        </div>

                    )}
                </Header>
                <Content className="overflow-auto h-screen">
                    {authUser && (
                        <>
                            {(authUser.employee_status_id.toString() !== "1") ? (
                                <NoPageAccess/>
                            ) : (<App match={match}/>)}
                        </>
                    )}


                </Content>
                <Footer>
                    <div className="gx-layout-footer-content">
                        {footerText}
                    </div>
                </Footer>


        </Layout>
    )
};
export default MainApp;

