import React from "react";

class Helpers {

    static objectFormBuilder(form, object) {


        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                if (object[name] === undefined || object[name] === null || object[name] === "null") {

                    form.append(name, '');
                } else {

                    form.append(name, object[name]);
                }
            }
        }

        return form;
    }

    static normalizeJson(object) {
        let newObject = {};
        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                newObject[name] = object[name] ? object[name].toString() : '';
            }
        }

        return newObject;

    }

    static normalizeJsonStringify(object) {
        let newObject = {};
        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                newObject[name] = object[name] !== null ? object[name].toString() : '';
            }
        }

        return newObject;

    }

    getDeviceName() {
        let name = navigator.appCodeName;
        return name.replace(" ", "_").toLowerCase();
    }

    static getIdFromSlug(slug) {
        let name = slug.split("-");
        return name[name.length - 1];

    }

    static getTitleFromSlug(slug) {
        if (slug) {

            let name = slug.split("-");
            let length = name.length - 1;
            let title = "";
            for (let i = 0; i < length; i++) {
                title += name[i] + " ";
            }
            return title;
        }
        return "";

    }

    static getMomentDateFromSQL(date, moment) {
        if (date) {
            return moment(new Date(date));
        }
        return '';
    }

    static getSQLDateFromMoment(date) {
        let dateFormat = 'YYYY-MM-DD';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static getSQLTimeFromMoment(date) {
        let dateFormat = 'HH:mm:ss';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static getSQLDateTimeFromMoment(date) {
        let dateFormat = 'YYYY-MM-DD HH:mm:ss';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static displayDate(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return day + " " + month + ", " + year;
        }
        return '';
    }

    static displayMonthYear(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return month + " " + year;
        }
        return '';
    }

    static displayDayMonth(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];

            return day + " " + month;
        }
        return '';
    }

    static displayDateTime(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        if (date) {
            let dateTime = new Date(date);

            return dateTime.getDate() + " " + months[dateTime.getMonth()] + " " + dateTime.getFullYear() + " " + new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours(), dateTime.getMinutes(), dateTime.getSeconds()).toLocaleTimeString();

        }
        return '';
    }

    static download(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static downloadOrOpen(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static genderFromValue(value) {
        if (value) {
            if (value.toString() === '1') {
                return "Female";
            } else if (value.toString() === '2') {
                return "Male";
            }
        }
        return "Undecided";
    }

    static normalizeValue(value) {
        if (value) {
            return value;
        }
        return "";
    }

    static createSlug(title, id) {
        return title.replace(" ", "-") + "-" + id;
    }

    /*------------------------------
 *
 * ------------------------------
 *The authorize me must
 *ensure that it
 *has the application, type
 * and menu
 *
  */

    static authorizeMe(auth, type, menu) {
        try {

            return auth[type].includes(menu);

        } catch (e) {

        }
        return false;

    }

    static prepareAddress(address) {
        if (address) {

            let addIndex = address.indexOf("[");
            if (addIndex >= 0) {
                let parsAddress = JSON.parse(address);
                if (parsAddress.length > 0) {
                    let parsedAddress = parsAddress[0];

                    address = parsedAddress.house_number + ", " + parsedAddress.street_name + " " + parsedAddress.location + " " + parsedAddress.city

                } else {
                    return "";
                }
            }
        }

        return address;

    }
    static   filterData (data, name) {
        let records = [];
        data.forEach(record => {
            records.push({
                text: record[name],
                value: record.id
            });
        });
        return records;
    }

}

export default Helpers;